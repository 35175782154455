import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RecipeComponent } from './recipe/recipe.component';
import { ResultsComponent } from './results/results.component';
import { SearchPageComponent } from './search-page/search-page.component';

const routes: Routes = [
  { path: 'home', component: SearchPageComponent },
  { path: 'searchResults', component: ResultsComponent },
  { path: 'recipe', component: RecipeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
