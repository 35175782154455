<div class="parent-div">
  <header class="container p-1 bottom-shadow" style="width: 100%">
    <div class="container mt-3 pb-0 has-text-centered">
      <h1
        class="title poppins"
        style="
          font-size: 2.4em;
          font-weight: 700;
          color: rgba(24, 24, 24, 0.849);
        "
      >
        <a [routerLink]="['/home']" class="nostyle">Recipe Retriever</a>
      </h1>
    </div>

    <form #form="ngForm" (ngSubmit)="formSubmit(form)">
      <div class="inner-form">
        <div class="input-field">
          <button class="btn-search" type="submit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
              ></path>
            </svg>
          </button>
          <input
            name="q"
            id="search"
            type="text"
            class="form-control"
            placeholder="Search for a recipe"
            [(ngModel)]="searchQuery"
            aria-label="Search"
          />
        </div>
      </div>
    </form>
  </header>

  <div style="background-color: #f1f3f4; min-height: 100vh">
    <div
      class="container pt-1 body-main-container"
      style="background-color: #f1f3f4"
    >
      <div
        class="spinner-styles"
        [ngClass]="{
          'zero-margin-snipper': cardsLoaded === true,
          'normal-margin-snipper': cardsLoaded === false
        }"
      >
        <mat-progress-spinner
          *ngIf="cardsLoaded === false"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>
      <div class="column p-1" *ngFor="let result of searchModel._results; let i = index">
        <div class="card">
          <div class="card-content">
            <div class="content mb-2">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: left;
                "
              >
                <img
                  src="https://s2.googleusercontent.com/s2/favicons?domain_url=https://www.{{
                    result.host
                  }}"
                />
                <p
                  class="font-assistant subtitle is-7"
                  style="
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  &nbsp;&nbsp;&nbsp;<a
                    href="{{ result.link }}"
                    style="color: rgb(32, 32, 32)"
                    >{{ result.link }}</a
                  >
                </p>
              </div>
              <p
                class="font-cabin mt-3"
                style="font-size: 1.3em; font-weight: 400"
              >
                <a
                  (click)="clickRecipe(result.link, i+1)"
                  class="toplink"
                  >{{ result.title }}
                </a>
              </p>
            </div>

            <div class="media">
              <div class="media-content">
                <p
                  class="font-assistant subtitle is-6"
                  style="overflow: hidden"
                >
                  {{ result.text.slice(0, 200).trim() }}...
                </p>
              </div>

              <div class="media-right">
                <figure class="image is-128x128">
                  <img
                    class="card-image-fit"
                    src="{{ result.image }}"
                    alt="Recipe Image"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
