import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RecipeComponent } from './recipe/recipe.component';
import { ResultsComponent } from './results/results.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { ResultCardComponent } from './result-card/result-card.component';
import { RecipeImageDialogComponent } from './recipe-image-dialog/recipe-image-dialog.component';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {AnalyticsService} from './analytics.service';

@NgModule({
  declarations: [
    AppComponent,
    RecipeComponent,
    ResultsComponent,
    SearchPageComponent,
    ResultCardComponent,
    RecipeImageDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
  ],
  entryComponents: [RecipeImageDialogComponent],
  providers: [
    AnalyticsService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
