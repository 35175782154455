import { Component, OnInit, OnDestroy } from '@angular/core';
import { SearchModel } from '../SearchModel';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../analytics.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css'],
})
export class SearchPageComponent implements OnInit, OnDestroy {
  searchQuery: string;

  constructor(
    // Injecting Router:
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {}

  formSubmit(form: NgForm): void {
    this.router.navigate(['searchResults'], {
      queryParams: { query: this.searchQuery },
    });

    this
     .analyticsService
     .eventEmitter('search', 'engagement', this.searchQuery );
  }

  ngOnDestroy(): void {}
}
