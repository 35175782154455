import { Component, OnInit, OnDestroy } from '@angular/core';
import { SearchModel } from '../SearchModel';
import { SearchService } from '../search.service';
import { Recipe } from '../Recipe';
import { RecipeService } from '../recipe.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AnalyticsService } from '../analytics.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css'],
})

export class ResultsComponent implements OnInit, OnDestroy {
  recipe: Recipe;
  searchModel: SearchModel = new SearchModel();
  searchQuery: string;
  querySub: Subscription;
  querySub1: Subscription;
  cardsLoaded: boolean = false;

  constructor(
    private analyticsService: AnalyticsService,
    private dataServiceRecipe: RecipeService,
    private dataService: SearchService,
    // Injecting Router:
    private router: Router,
    // Injecting ActivatedRoute
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.querySub = this.route.queryParams.subscribe((params) => {
      this.searchQuery = params.query;

      let recipeSearchQuery = this.searchQuery;

      if(!this.searchQuery.includes('recipe')){
        recipeSearchQuery += ' recipe';
      }

      this.getResults(recipeSearchQuery);
    });
  }

  formSubmit(form: NgForm): void {
    this.router.navigate(['searchResults'], {
      queryParams: { query: this.searchQuery },
    });

    this.cardsLoaded = false;

    this
    .analyticsService
    .eventEmitter('search', 'engagement', this.searchQuery );
  }


  clickRecipe(resultLink: string, index){
    this.router.navigate(['/recipe'], {queryParams: { url: resultLink }});

    this
    .analyticsService
    .eventEmitter('select_content', 'engagement', this.analyticsService.extractRootDomain(resultLink), index);
  }

  getResults(searchQuery): void {
    this.querySub = this.dataService
      .getQueryResults(searchQuery)
      .subscribe((data) => {
        if (data) {
          this.searchModel = data;
        }

        this.searchModel._results.forEach((result, i) => {
          this.querySub1 = this.dataServiceRecipe
            .getRecipe(result.link)
            .subscribe((data) => {

              let discard = false;
              if (data.recipe.instructions.length > 0) {
                result.image = data.recipe.image;
                result.text = data.recipe.instructions;
                result.title = data.recipe.title.replace(/(https|www).*/, '');
              } else discard = true;

              if (discard) {
                this.searchModel._results.splice(i, 1);
              }

              this.cardsLoaded = true;
            });
        });
      });

      this
     .analyticsService
     .eventEmitter('view_search_results', 'engagement', this.searchQuery );
  }

  ngOnDestroy(): void {
    if (this.querySub) {
      this.querySub.unsubscribe();
    }
  }
}
