export class SearchModel {
  _results: [
    {
      host: string;
      link: string;
      text: string;
      title: string;
      image: string;
    }
  ];
}
