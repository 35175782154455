import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Recipe } from '../Recipe';
import { RecipeService } from '../recipe.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RecipeImageDialogComponent } from '../recipe-image-dialog/recipe-image-dialog.component';
import { AnalyticsService } from '../analytics.service';

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.css'],
})
export class RecipeComponent implements OnInit, OnDestroy {
  recipe: Recipe;
  querySub: Subscription;
  directionStatus: boolean[] = new Array();


  constructor(
    private analyticsService: AnalyticsService,
    private dataService: RecipeService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.querySub = this.route.queryParams.subscribe((params) => {
      this.getRecipe(params.url);
    });
  }

  getRecipe(searchURL): void {
    this.querySub = this.dataService.getRecipe(searchURL).subscribe((data) => {
      if (data) {
        this.recipe = data;
        this.recipe.recipe.instructions.split('\n').forEach(()=>{
          this.directionStatus.push(false);
        })
      }
    });
  }

  directionClicked(i){
    this.directionStatus[i] = !this.directionStatus[i];

    this
    .analyticsService
    .eventEmitter('select_content', 'engagement', 'instruction' );
  }

  openDialog(): void {
    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(RecipeImageDialogComponent, {
        data: {
          source: this.recipe.recipe.image,
          name: this.recipe.recipe.title,
        },
        closeOnNavigation: true,
      });
    });

    this
    .analyticsService
    .eventEmitter('view_item', 'engagement', 'Recipe Image Dialog' );
  }
  ngOnDestroy(): void {
    if (this.querySub) {
      this.querySub.unsubscribe();
    }
  }
}
