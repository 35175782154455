import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Recipe } from './Recipe';

@Injectable({
  providedIn: 'root',
})
export class RecipeService {
  // Defining the API url:
  apiURL = 'https://r2qvuel6zagkmrw33w5knzwwtq0cmfnv.lambda-url.us-east-1.on.aws';

  constructor(
    // Injecting HttpClient service:
    private http: HttpClient
  ) {}

  getRecipe(searchURL): Observable<Recipe> {
    let URL = `${this.apiURL}/recipe?url=${searchURL}`;

    return this.http.get<Recipe>(URL);
  }
}
