import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { SearchModel } from './SearchModel';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  // Defining the API url:
  apiURL = 'https://utt7tqg46qfp2dk746v34fbdaq0bssvk.lambda-url.us-east-1.on.aws';

  constructor(
    // Injecting HttpClient service:
    private http: HttpClient
  ) {}

  getQueryResults(searchQuery): Observable<SearchModel> {
    let URL = `${this.apiURL}/search?query=${searchQuery}`;

    return this.http.get<SearchModel>(URL);
  }
}
