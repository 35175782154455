import { Component } from '@angular/core';
import{Router, NavigationEnd} from '@angular/router';
import {Title} from "@angular/platform-browser";

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'recipe-app';

  constructor(public router: Router, private titleService:Title){
    this.titleService.setTitle("Recipe Retriever");
    this.router.events.subscribe(event => {
       if(event instanceof NavigationEnd){
           gtag('config', 'G-11EQR66EV0',
                 {
                   'page_path': event.urlAfterRedirects
                 }
                );
        }
     }
  )}
}
