<section class="hero is-info">
  <!--Back button-->
  <span style="position: relative; top: 20px; left: 20px; width: 100px">
    <span [routerLink]="['/home']" class="icon-text back-button-top">
      <span class="icon back-icon-top">
        <i class="fas fa-arrow-left fa-lg"></i>
      </span>
    </span>
  </span>

  <div class="hero-body">
    <div class="level">
      <div class="level-item has-text-centered">
        <figure>
          <img
            class="circular-image"
            (click)="openDialog()"
            src="{{ recipe.recipe.image }}"
          />
        </figure>
      </div>
    </div>
    <h1 class="title is-size-1 has-text-centered poppins">
      {{ recipe.recipe.title }}
    </h1>

    <div class="level is-justify-content-space-around">
      <div class="level-item has-text-centered">
        <div class="level">
          <span class="icon">
            <i class="fas fa-globe"></i>
          </span>
          <p class="has-text-weight-bold">Taken from:&nbsp;</p>
          <a href="https://www.{{ recipe.recipe.host }}" class="pangolinFont">{{
            recipe.recipe.host
          }}</a>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div class="level">
          <span class="icon">
            <i class="fas fa-seedling"></i>
          </span>
          <p class="has-text-weight-bold">Ingredients:&nbsp;</p>
          <p class="pangolinFont">7</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div class="level">
          <span class="icon">
            <i class="fas fa-concierge-bell"></i>
          </span>
          <p class="has-text-weight-bold">Yield:&nbsp;</p>
          <p class="pangolinFont">{{ recipe.recipe.yields }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div class="level">
          <span class="icon">
            <i class="fas fa-clock"></i>
          </span>
          <p class="has-text-weight-bold">Time:&nbsp;</p>
          <p class="pangolinFont">{{ recipe.recipe.total_time }} mins</p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Gives us information about the ingredients-->
<div class="container has-text-centered mb-6 p-5">
  <div class="level">
    <div class="level-item">
      <div>
        <h1 class="title is-size-2 poppins m-3">Ingredients</h1>
        <div class="field is-flex-direction-row has-text-justified">
          <ul>
            <li *ngFor="let ingredient of recipe.recipe.ingredients">
              <label class="checkbox p-1">
                <input type="checkbox" class="strikethrough" />
                <span class="pangolinFont">&nbsp;&nbsp;{{ ingredient }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!--gives us information about the directions-->
<div class="container has-text-justified p-5">
  <h1 class="title is-size-2 has-text-centered poppins">Directions</h1>
  <ul class="is-vcentered">

    <li
      *ngFor="
        let instruction of recipe.recipe.instructions.split('\n');
        let i = index
      "
      (click)="directionClicked(i)"
      [ngClass]="directionStatus[i] ? 'direction-clicked' : 'direction-not-clicked'"
      class="pangolinFont p-2 track-directions"
    >
      {{ i + 1 }}. {{ instruction }}
      <span class="icon" [ngClass]="directionStatus[i] ? 'display-checkmark' : 'hide-checkmark'">
        <i class="fas fa-check direction-checkmark" ></i>
      </span>
    </li>
  </ul>
</div>
