import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-recipe-image-dialog',
  templateUrl: './recipe-image-dialog.component.html',
  styleUrls: ['./recipe-image-dialog.component.css'],
})
export class RecipeImageDialogComponent {
  closeOnNavigation: true;

  constructor(
    public dialogRef: MatDialogRef<RecipeImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
}
